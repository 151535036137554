
import AppPage from "./pages/AppPage";
import { connect } from "react-redux";
import StreamPage from "./pages/StreamPage";
import NodeUsersPage from "./pages/NodeUsersPage";
import React, { Suspense, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import UserProfilePage from "./modules/UserProfile/UserProfilePage";
import ResetPassword from "./modules/Auth/pages/ResetPassword";

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "affa5f6c-5105-40b4-aa44-2c1d1d3a0a0c",
    /* ...Other Configuration Options... */
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView();



function BasePage(props) {
  useEffect(() => {
    props.requestApps();
    props.requestDevices();
  });

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/stream" />
        }

        <Route path="/stream/:type?/:id?" component={StreamPage}></Route>
        <ContentRoute path="/app/:process/:id?" component={AppPage} />
        <ContentRoute path="/node-users" component={NodeUsersPage} />
        <ContentRoute path="/user-profile" component={UserProfilePage} />
        <ContentRoute path="/account/forgot-password" component={ResetPassword} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}

export default connect(null, auth.actions)(BasePage);
