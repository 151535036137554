import { connect } from "react-redux";
import React, { Component } from "react";
import * as signalR from "@microsoft/signalr";
import * as auth from "../modules/Auth/_redux/authRedux";
import {
  getConnectionInfo,
  getDevicesByApp,
  getAppsByDevice,
} from "../utils/api";

import methodTypes from "../utils/methodTypes";
import RequestDetail from "../components/RequestDetail";
import RequestFilter from "../components/RequestFilter";
import RequestTable from "../components/RequestTable";
import parseParams from "../utils/parseParams";
import checkSelectedFilterItem from "../utils/checkSelectedFilterItem";

class StreamPage extends Component {
  state = {
    apps: [],
    devices: [],
    messages: [],
    filteredMessages: [],
    detail: {},
    chipData: [
      { id: "get", name: "GET" },
      { id: "post", name: "POST" },
      { id: "put", name: "PUT" },
      { id: "delete", name: "DELETE" },
    ],
    selectedFilterItems: [],
    filterItems: [],

    isDetail: false,
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }

  setTab = (_tabName) => {
    this.setState({ selectedTab: _tabName });
  };

  componentDidMount() {

    const { setConnection } = this.props;

    window.gtag('event', 'screen_view', {
      'screen_name': "Stream Page"
    });

    this.getFilterItems();
    getConnectionInfo().then((info) => {
      info.accessToken = info.data.accessToken || info.data.accessKey;
      info.url = info.data.url || info.data.endpoint;

      const options = {
        accessTokenFactory: () => info.accessToken,
      };

      const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(info.url, options)
        .configureLogging(signalR.LogLevel.Information)
        .withAutomaticReconnect()
        .build();

      hubConnection
        .start()
        .then(() => {
          setConnection(true);
        })
        .catch((err) => {
          setConnection(false);
        });

      hubConnection.onreconnecting((error) => {
        setConnection(false);
      });

      hubConnection.on("newPackage", (message) => {

        let isUpdated = false

        let newList = this.state.messages.map((item) => {

          if (item.id == message.id) {
            let updatedItem = {}

            if (message.responsePackage) {
              updatedItem = {
                ...item,
                responsePackage: message.responsePackage,
              };
            }
            else {
              updatedItem = {
                ...item,
                requestPackage: message.requestPackage,
              };
            }

            isUpdated = true

            return updatedItem;
          }

          return item;

        });

        if (!isUpdated) {
          newList = [message, ...newList]
        }

        this.setState({ messages: newList });
        this.filteredMessages();
      });

    });





  }

  checkFilterItems(requestPackage, id) {
    let result = false;

    if (this.state.selectedFilterItems.length === 0) {
      result = true;
    }

    this.state.selectedFilterItems.forEach((item) => {
      if (
        item.id === "post" ||
        item.id === "get" ||
        item.id === "put" ||
        item.id === "delete"
      ) {
        if (item.name === methodTypes[requestPackage.methodType]) {
          result = true;
        }
      }
      else if (item.id === "ip") {
        if (item.name === requestPackage.localIpAddress) {
          result = true
        }
      }
      else {
        if (item.id === id) {
          result = true;
        }
      }
    });

    return result;
  }

  filterMessage(message) {
    let { type, id } = this.props.match.params;

    let isMessage;

    if (type && id) {
      if (type === "device") {
        if (id === message.deviceId) {
          if (
            this.checkFilterItems(message.requestPackage, message.appId) ===
            true
          ) {
            isMessage = true;
          }
        }
      } else {
        if (id === message.appId) {
          if (
            this.checkFilterItems(message.requestPackage, message.deviceId) ===
            true
          ) {
            isMessage = true;
          }
        }
      }
    } else {
      if (this.checkFilterItems(message.requestPackage) === true) {
        isMessage = true;
      }
    }

    if (isMessage) {
      return message;
    }
  }

  filteredMessages() {
    let messages = [];

    this.state.messages.forEach((message) => {
      if (this.filterMessage(message)) {
        messages.push(this.filterMessage(message));
      }
    });

    this.setState({ filteredMessages: messages });
  }

  getFilterItems() {

    if (this.props.match.params.type) {
      if (this.props.match.params.type === "app") {
        getDevicesByApp(this.props.match.params.id).then((response) => {
          this.setState({
            filterItems: [...this.state.chipData, ...response.data.devices],
          });
        });
      } else {
        getAppsByDevice(this.props.match.params.id).then((response) => {
          this.setState({
            filterItems: [...this.state.chipData, ...response.data.apps],
          });
        });
      }
    } else {
      this.setState({ filterItems: this.state.chipData });
    }

    fetch("https://api.ipify.org/?format=json").then(response => response.json()).then(response => {
      this.setState({ filterItems: [...this.state.filterItems, { id: "ip", name: response.ip }] })
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.filteredMessages();
      this.getFilterItems();
    }
  }

  removeItem() {
    this.setState({ isDetail: false, detail: {} });
  }

  selectItem(item) {
    const url = new URL(item.requestPackage.url);
    let params = parseParams(url.search);

    this.setState({ isDetail: true, detail: { params: params, ...item } });
  }

  handleClick(id, name) {
    let item = { id, name };

    if (checkSelectedFilterItem(this.state.selectedFilterItems, id)) {
      let array = this.state.selectedFilterItems.filter(function (item) {
        return item.id !== id;
      });

      this.setState(
        { filteredMessages: [], selectedFilterItems: array },
        () => {
          this.filteredMessages();
        }
      );
    } else {
      this.setState(
        {
          filteredMessages: [],
          selectedFilterItems: [item, ...this.state.selectedFilterItems],
        },
        () => {
          this.filteredMessages();
        }
      );
    }
  }

  render() {
    return (
      <div>
        <RequestFilter
          data={this.state.filterItems}
          selectedFilterItems={this.state.selectedFilterItems}
          onClick={this.handleClick}
        />
        <div className="card card-custom mt-1">
          <div className="card-body p-1 d-flex">
            <RequestTable
              data={this.state.filteredMessages}
              selectItem={this.selectItem}
              activeItem={this.state.detail.id}
            />
            <RequestDetail
              data={this.state.detail}
              isDetail={this.state.isDetail}
              removeItem={this.removeItem}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, auth.actions)(StreamPage);
