import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SVG from "react-inlinesvg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { inviteNodeUser, deleteNodeUser, getNodeUsers } from "../utils/api";
import { toAbsoluteUrl } from "../../_metronic/_helpers";


/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  name: "",
};

function NodeUsersPage(props) {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  window.gtag('event', 'screen_view', {
    'screen_name': "Node Users Page"
  });

  const getUsers = () => {
    getNodeUsers().then((response) => {
      setUsers(response.data.users);
    });
  };

  const deleteUser = (data) => {
    deleteNodeUser(data).then((response) => {
      toast("User is removed successfully");
      getUsers();
    });
  };


  useEffect(() => {
    getUsers();
  }, []);

  const AppSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required("Minimum 3 characters"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AppSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      inviteNodeUser({ value: values.email })
        .then((response) => {
          disableLoading();
          setSubmitting(false);
          getUsers();
          toast("User is added successfully");
        })
        .catch((error) => {
          disableLoading();
          setSubmitting(false);
          toast.error(error.response.data.UserFriendlyMessage);
        });
    },
  });

  useEffect(() => {}, []);

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <ToastContainer />
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-10">
        <h3 className="font-size-h1">Manage Node</h3>
        {/* <p className="text-muted font-weight-bold">Enter app name</p> */}
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null}

        <div className="form-group fv-plugins-icon-container">
          <label>User Email</label>
          <input
            placeholder="Email"
            type="email"
            className={`form-control h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="user"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Add</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
      <div className="card card-custom">
        <div className="card-header border-0 pt-5" style={{ minHeight: 40 }}>
          <h5 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Node Users
            </span>
          </h5>
        </div>
        <div className="card-body pt-3">
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th
                    scope="col"
                    className="text-center"
                    style={{ width: 100 }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.length === 0 ? (
                  <tr>
                    <td colSpan="4" align="center">
                      There is no data
                    </td>
                  </tr>
                ) : (
                  users.map((user) => (
                    <tr>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td>{user.email}</td>
                      <td className="text-center">
                        <a
                          href="#"
                          onClick={() => deleteUser({ value: user.email })}
                          className="btn btn-icon btn-light btn-sm"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Trash.svg"
                              )}
                            ></SVG>
                          </span>
                        </a>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NodeUsersPage;
