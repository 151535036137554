/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import SVG from "react-inlinesvg";
import Avatar from 'react-avatar';
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import * as auth from "../../../../../app/modules/Auth/_redux/authRedux";



function QuickUser(props) {
  const history = useHistory();

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };



  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-left offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5">
            <Avatar name={`${props.user.userName}`} round="5px" />
          </div>
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {props.user.userName}
            </a>
            <div className="navi mt-2">
            </div>
          </div>
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />



        <div className="navi navi-spacer-x-0 p-0">

          <Link to="/user-profile" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/User.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Profile</div>
                <div className="text-muted">
                  Account settings and more
                </div>
              </div>
            </div>
          </Link>
          
          <button
            className="btn btn-light-primary btn-bold mt-10"
            onClick={logoutClick}
          >
            Sign out
          </button>

        </div>


      </div>
    </div>
  );
}

export default connect(({ auth }) => {
  return { user: auth.user, refreshToken: auth.refreshToken };
}, auth.actions)(QuickUser);
