import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, call } from "redux-saga/effects";
import { getUserByToken, getDevices, getApps } from "../../../utils/api";

export const actionTypes = {
  Login: "[Login] Action",
  RefreshNode: "[RefreshNode] Action",
  RefreshToken: "[RefreshToken] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  AppsRequested: "[Request Apps] Action",
  AppsLoaded: "[Load Apps] Auth API",
  DevicesRequested: "[Request Devices] Action",
  DevicesLoaded: "[Load Devices] Auth API",
  ConnectionLoaded: "[Load Connection] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  refreshToken: undefined,
  nodeId: undefined,
  devices: [],
  apps: [],
  connection: undefined,
};

export const reducer = persistReducer(
  {
    storage,
    key: "v706-demo3-auth",
    whitelist: ["user", "authToken", "refreshToken", "nodeId"],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.RefreshToken: {
        const { authToken, refreshToken, nodeId } = action.payload;

        return {
          authToken,
          refreshToken,
          nodeId,
          user: {},
          devices: [],
          apps: [],
        };
      }

      case actionTypes.RefreshNode: {
        const { authToken, refreshToken, nodeId } = action.payload;

        return {
          authToken,
          refreshToken,
          nodeId,
          user: {},
          devices: [],
          apps: [],
        };
      }

      case actionTypes.Login: {
        const { authToken, refreshToken, nodeId } = action.payload;

        return {
          authToken,
          refreshToken,
          nodeId,
          user: {},
          devices: [],
          apps: [],
        };
      }

      case actionTypes.Register: {
        const { authToken, refreshToken, nodeId } = action.payload;

        return { authToken, refreshToken, nodeId, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.AppsLoaded: {
        const { apps } = action.payload;
        return { ...state, apps };
      }

      case actionTypes.DevicesLoaded: {
        const { devices } = action.payload;
        return { ...state, devices };
      }

      case actionTypes.ConnectionLoaded: {
        const { connection } = action.payload;

        return { ...state, connection };
      }

      case actionTypes.DevicesLoaded: {
        const { devices } = action.payload;

        return { ...state, devices };
      }

      case actionTypes.AppsLoaded: {
        const { apps } = action.payload;

        return { ...state, apps };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  refreshToken: (authToken, refreshToken, nodeId) => ({
    type: actionTypes.RefreshToken,
    payload: { authToken, refreshToken, nodeId },
  }),
  refreshNode: (authToken, refreshToken, nodeId) => ({
    type: actionTypes.RefreshNode,
    payload: { authToken, refreshToken, nodeId },
  }),
  login: (authToken, refreshToken, nodeId) => ({
    type: actionTypes.Login,
    payload: { authToken, refreshToken, nodeId },
  }),
  register: (authToken, refreshToken, nodeId) => ({
    type: actionTypes.Register,
    payload: { authToken, refreshToken, nodeId },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setConnection: (connection) => ({
    type: actionTypes.ConnectionLoaded,
    payload: { connection },
  }),
  requestDevices: (devices) => ({
    type: actionTypes.DevicesRequested,
    payload: { devices },
  }),
  requestApps: (apps) => ({
    type: actionTypes.AppsRequested,
    payload: { apps },
  }),
  setDevices: (devices) => ({
    type: actionTypes.DevicesLoaded,
    payload: { devices },
  }),
  setApps: (apps) => ({ type: actionTypes.AppsLoaded, payload: { apps } }),
};

export function* saga() {
  yield takeLatest(actionTypes.RefreshToken, function* refreshTokenSaga() {
    yield put(actions.requestUser());
    yield put(actions.requestDevices());
    yield put(actions.requestApps());
  });

  yield takeLatest(actionTypes.RefreshNode, function* refreshNodeSaga() {
    yield put(actions.requestUser());
    window.location.reload();
  });

  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });

  yield takeLatest(actionTypes.DevicesRequested, function* devicesRequested() {
    try {
      const response = yield call(getDevices);

      if (response) {
        yield put(actions.setDevices(response.data.devices));
      }
    } catch {
      yield put(actions.setDevices([]));
    }
  });

  yield takeLatest(actionTypes.AppsRequested, function* appsRequested() {
    try {
      const response = yield call(getApps);

      if (response) {
        yield put(actions.setApps(response.data.apps));
      }
    } catch {
      yield put(actions.setApps([]));
    }
  });
}
