import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_helpers";

function AsideSearch(props) {
  const { isActive } = props;

  const [apps, handleApps] = useState([]);
  const [devices, handleDevices] = useState([]);

  useEffect(() => {
    if (props.apps && props.devices) {
      handleApps(props.apps);
      handleDevices(props.devices);
    }
  }, [props.devices, props.apps]);

  const handleChange = (event) => {
    const value = event.target.value.toLowerCase();

    const appResults = props.apps.filter((app) =>
      app.name.toLowerCase().includes(value)
    );
    const deviceResults = props.devices.filter((device) => {
      return (
        device.userFriendlyName.toLowerCase().includes(value) ||
        device.name.toLowerCase().includes(value)
      );
    });

    handleApps(appResults);
    handleDevices(deviceResults);
  };

  return (
    <div
      className={`tab-pane p-3 px-lg-5 py-lg-5 fade ${isActive &&
        "show active"}`}
    >
      {/* begin::Form */}
      <form className="p-2 p-lg-3">
        <div className="d-flex">
          <div className="input-icon h-40px" style={{ width: "100%" }}>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid h-40px"
              placeholder="Search..."
              id="generalSearch"
              onChange={handleChange}
              autoComplete="false"
            />
            <span>
              <span className="svg-icon svg-icon-lg">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                />
              </span>
            </span>
          </div>
        </div>
      </form>
      {/* end::Form */}
      <h3 className="p-2 p-lg-3 my-1 my-lg-3">
        Apps
        <NavLink
          to={`/app/new`}
          className="text-muted text-hover-primary"
          style={{
            fontSize: "12px",
            right: "30px",
            position: "absolute",
            lineHeight: "24px",
          }}
        >
          <i className="ki ki-plus icon-sm"></i> Create a new app
        </NavLink>
      </h3>

      {/* begin::List */}
      {apps.length === 0 && <div><p className="text-center primary">Your apps will be displayed here</p><p className="text-center primary" style={{ fontWeight: "bold" }}>First, you need to create a new app</p></div>}
      {apps.map((app, index) => (
        <NavLink
          key={index}
          to={`/stream/app/${app.id}?title=${app.name}`}
          className={`menu-link list-item block hoverable p-2 p-lg-3 mb-2`}
          activeClassName="active"
        >
          <div className="d-flex align-items-center">
            {/* begin::Symbol */}
            <div className="symbol symbol-40 symbol-light mr-4">
              <span className="symbol-label bg-hover-white">
                <span className="svg-icon h-50 align-self-center">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Devices/CPU2.svg")}
                  />
                </span>
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Text */}
            <div className="d-flex flex-column flex-grow-1 mr-2">
              <span className="text-dark-75 font-size-h6 mb-0">{app.name}</span>
            </div>
            <NavLink
              to={`/app/edit/${app.id}`}
              className="text-muted text-hover-primary"
              style={{ fontSize: "12px" }}
            >
              Edit
            </NavLink>
            {/* begin::End */}
          </div>
        </NavLink>
      ))}

      {/* end::List */}

      <h3 className="p-2 p-lg-3 my-1 my-lg-3">Devices</h3>

      {/* begin::List */}
      {devices.length === 0 && (
        <p className="text-center">
          Devices will be listed when they connected
        </p>
      )}
      {devices.map((device, index) => (
        <NavLink
          key={index}
          to={`/stream/device/${device.id}?title=${device.userFriendlyName}`}
          className={`menu-link list-item hoverable p-2 p-lg-3 mb-2`}
          activeClassName="active"
        >
          <div className="d-flex align-items-center">
            {/* begin::Symbol */}
            <div className="symbol symbol-40 symbol-light mr-4">
              <span className="symbol-label bg-hover-white">
                <span className="svg-icon h-50 align-self-center">
                  {device.operatingSystem === "iOS" ? (
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Devices/iPhone-X.svg"
                      )}
                    />
                  ) : (
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Devices/Android.svg"
                      )}
                    />
                  )}
                </span>
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Text */}
            <div className="d-flex flex-column flex-grow-1 mr-2">
              <span className="text-dark-75 font-size-h6 mb-0">
                {device.userFriendlyName}
              </span>
              <span className="text-muted">{device.name}</span>
            </div>
            {/* begin::End */}
          </div>
        </NavLink>
      ))}
      {/* end::List */}
    </div>
  );
}

export default connect(({ auth }) => {
  return { devices: auth.devices, apps: auth.apps };
}, null)(AsideSearch);
