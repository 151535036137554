import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import { useFormik } from "formik";
import clsx from "clsx";
import copy from "clipboard-copy";
import { Tooltip } from "@material-ui/core";
import { Tabs, Tab } from "react-bootstrap";

import * as Yup from "yup";
import {
  registerApp,
  updateApp,
  getApp,
  getIosMd,
  getAndroidMd,
  getCoreEndpointListenerMD,
  getCoreHttpClientListenerMD,
  getHttpClientListenerStandardMD,
} from "../utils/api";
import * as auth from "../../app/modules/Auth/_redux/authRedux";



const initialValues = {
  name: "",
};

function AppPage(props) {
  const [isCopySucceed, setIsCopySucceed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [appKey, setAppKey] = useState();
  const [iosMd, setIosMd] = useState();
  const [androidMd, setAndroidMd] = useState();
  const [coreEndpointListenerMD, setCoreEndpointListenerMD] = useState();
  const [coreHttpClientListenerMD, setCoreHttpClientListenerMD] = useState();
  const [
    httpClientListenerStandardMD,
    setHttpClientListenerStandardMD,
  ] = useState();


  window.gtag('event', 'screen_view', {
    'screen_name': "App Page"
  });

  const AppSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required("Minimum 3 characters"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const copyKey = () => {
    copy(appKey).then(() => {
      setIsCopySucceed(true);
      setTimeout(() => {
        setIsCopySucceed(false);
      }, 2000);
    });
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AppSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        if (props.match.params.process === "edit") {
          updateApp({ id: values.id, name: values.name })
            .then((response) => {
              props.requestApps();
              setSubmitting(false);
              disableLoading();
            })
            .catch(() => {
              disableLoading();
              setSubmitting(false);
            });
        } else {
          registerApp({ name: values.name })
            .then((response) => {
              props.requestApps();
              disableLoading();
              setSubmitting(false);
              props.history.push("/app/edit/" + response.data.id);
            })
            .catch(() => {
              disableLoading();
              setSubmitting(false);
            });
        }
      }, 1000);
    },
  });


  useEffect(() => {
    if (props.match.params.process === "edit") {
      getApp(props.match.params.id).then((response) => {
        setAppKey(response.data.keyId);
        formik.setValues({
          id: response.data.id,
          name: response.data.name,
          keyId: response.data.keyId,
          keySecret: response.data.keySecret,
        });
      });
    } else {
      formik.setValues({ name: "" });
    }
  }, [props.match.params.id]);

  useEffect(() => {
    getIosMd().then((response) => {
      setIosMd(response);
    });
    getAndroidMd().then((response) => {
      setAndroidMd(response);
    });
    getCoreEndpointListenerMD().then((response) => {
      setCoreEndpointListenerMD(response);
    });
    getCoreHttpClientListenerMD().then((response) => {
      setCoreHttpClientListenerMD(response);
    });
    getHttpClientListenerStandardMD().then((response) => {
      setHttpClientListenerStandardMD(response);
    });
  }, []);

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-10">
        <h3 className="font-size-h1">
          {props.match.params.process === "new" ? "Create App" : "Edit App"}
        </h3>
        {/* <p className="text-muted font-weight-bold">Enter app name</p> */}
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null}

        <div className="form-group fv-plugins-icon-container">
          <label>App Name</label>
          <input
            placeholder="App Name"
            type="name"
            className={`form-control h-auto py-5 px-6 ${getInputClasses(
              "name"
            )}`}
            name="name"
            {...formik.getFieldProps("name")}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.name}</div>
            </div>
          ) : null}
        </div>

        {props.match.params.process == "edit" && [
          <div className="form-group fv-plugins-icon-container example">
            <label>App Key</label>
            <div className="input-group">
              <input
                type="name"
                className={`form-control h-auto py-5 px-6`}
                name="keyId"
                {...formik.getFieldProps("keyId")}
                disabled
              />
              <div className="input-group-append">
                {/* <a href="#" class="btn btn-secondary" data-clipboard="true" data-clipboard-target="#kt_clipboard_1"><i class="la la-copy"></i></a> */}
                <Tooltip title="Copy key">
                  <span
                    style={{ height: 53, marginLeft: 0 }}
                    className={`btn btn-secondary example-copy ${clsx({
                      "example-copied": isCopySucceed,
                    })}`}
                    onClick={copyKey}
                  />
                </Tooltip>
              </div>
            </div>
          </div>,

          // <div className="form-group fv-plugins-icon-container">
          //   <label>Key Secret</label>
          //   <input
          //     type="name"
          //     className={`form-control h-auto py-5 px-6`}
          //     name="keySecret"
          //     {...formik.getFieldProps("keySecret")}
          //     disabled
          //   />
          // </div>,
        ]}

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>
              {props.match.params.process === "edit" ? "Save" : "Create"}
            </span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}

      <Tabs defaultActiveKey="ios" id="uncontrolled-tab-example">
        <Tab eventKey="ios" title="iOS">
          <div class="p-5">
            <ReactMarkdown source={iosMd} />
          </div>
        </Tab>
        <Tab eventKey="android" title="Android">
          <div class="p-5">
            <ReactMarkdown source={androidMd} />
          </div>
        </Tab>
        <Tab
          eventKey="coreEndpointListener"
          title=".Net Core Endpoint Listener"
        >
          <div class="p-5">
            <ReactMarkdown source={coreEndpointListenerMD} />
          </div>
        </Tab>
        <Tab
          eventKey="coreHttpClientListener"
          title=".Net Core HttpClient Listener"
        >
          <div class="p-5">
            <ReactMarkdown source={coreHttpClientListenerMD} />
          </div>
        </Tab>
        <Tab
          eventKey="standardHttpClientListener"
          title=".Net Standard HttpClient Listener"
        >
          <div class="p-5">
            <ReactMarkdown source={httpClientListenerStandardMD} />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default connect(null, auth.actions)(AppPage);
