import React from "react";
import checkSelectedFilterItem from "../utils/checkSelectedFilterItem";
import { Chip, withStyles } from "@material-ui/core";
import TagFacesIcon from "@material-ui/icons/TagFaces";

const useStyles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: theme.spacing(0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
});

const RequestFilter = (props) => {
  const { classes, data, onClick, selectedFilterItems } = props;

  return (
    <div>
      {data.map((item, index) => {
        let icon;

        if (item.label === "React") {
          icon = <TagFacesIcon />;
        }

        return (
          <Chip
            key={index}
            icon={icon}
            label={item.userFriendlyName || item.name}
            onClick={() => onClick(item.id, item.name)}
            className={classes.chip}
            style={{
              backgroundColor: checkSelectedFilterItem(
                selectedFilterItems,
                item.id
              )
                ? "#F0461E"
                : "#ffffff",

              color:
                checkSelectedFilterItem(selectedFilterItems, item.id) &&
                "#ffffff",
            }}
            variant={
              checkSelectedFilterItem(selectedFilterItems, item.id)
                ? "default"
                : "outlined"
            }
          />
        );
      })}
    </div>
  );
};

export default withStyles(useStyles)(RequestFilter);
