export default function checkSelectedFilterItem(filterItems, id) {
  let result = false;
  filterItems.forEach((selectedItem) => {
    if (selectedItem.id === id) {
      result = true;
    }
  });

  return result;
}
