import Viewer from "./Viewer";
import { getPackage } from "../utils/api";
import { Tabs, Tab } from "react-bootstrap";
import { List } from "react-content-loader";
import React, { useState, useEffect } from "react";



export default function RequestDetail(props) {
  const [data, handleData] = useState({});
  const [isDetail, handleIsDetail] = useState(false);
  const [loading, handleLoading] = useState(false);

  function closeDetail() {
    props.removeItem();
    handleIsDetail(false);
  }

  useEffect(() => {
    if (props.data.id) {
      handleLoading(true)
      getPackage(props.data.id).then((response) => {
        let url = {};
        if (response.data.requestPackage) {
          url = new URL(response.data.requestPackage.url);
        }

        handleData({ ...response.data, url });
        handleLoading(false)
      }).catch(e => {
        handleData({})
      })
    }

    handleIsDetail(props.isDetail);
  }, [props.data, props.isDetail]);

  return (
    isDetail && (
      <div className="detail">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <a
            href="#"
            onClick={() => closeDetail()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_panel_close"
          >
            <i className="ki ki-close icon-xs text-muted"></i>
          </a>
        </div>
        <Tabs defaultActiveKey="overview" id="uncontrolled-tab-example">
          <Tab eventKey="overview" title="Overview">
            <div class="p-3">
              <div className="mb-15">
                {loading ? <List /> :
                  <table
                    class="table table-bordered"
                    style={{ backgroundColor: "#fdfdfd" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">NAME</th>
                        <th scope="col">VALUE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Domain</td>
                        <td style={{ wordBreak: "break-word" }}>
                          {data.url && data.url.origin}
                        </td>
                      </tr>
                      <tr>
                        <td>Path</td>
                        <td style={{ wordBreak: "break-word" }}>
                          {data.url && data.url.pathname}
                          {data.url && data.url.search}
                        </td>
                      </tr>
                      <tr>
                        <td>Method</td>
                        <td>
                          {data.requestPackage && data.requestPackage.methodType}
                        </td>
                      </tr>
                      <tr>
                        <td>Status Code</td>
                        <td>
                          {data.responsePackage &&
                            data.responsePackage.statusCode}
                        </td>
                      </tr>
                    </tbody>
                  </table>}


                <h6>Query String Parameters</h6>
                {loading ? <List /> :
                  <table
                    class="table table-bordered"
                    style={{ backgroundColor: "#fdfdfd" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">NAME</th>
                        <th scope="col">VALUE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.params &&
                        Object.keys(data.params).map((key, index) => (
                          <tr>
                            <td>{key}</td>
                            <td>{data.params[key]}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </Tab>
          <Tab eventKey="request" title="Request">
            <div class="p-3">
              <h6>Header</h6>
              {loading ? <List /> :
                <table
                  class="table table-bordered"
                  style={{ backgroundColor: "#fdfdfd" }}
                >
                  <thead>
                    <tr>
                      <th scope="col">NAME</th>
                      <th scope="col">VALUE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.requestPackage && data.requestPackage.headerCouples &&
                      data.requestPackage.headerCouples.map((header) => (
                        <tr>
                          <td>{header.key}</td>
                          <td>{header.value}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              }
              <h6>Body</h6>
              {loading ? <List /> :
                <Viewer data={data.requestPackage} />
              }
            </div>
          </Tab>
          <Tab eventKey="response" title="Response">
            <div class="p-3">
              <h6>Header</h6>
              {loading ? <List /> :
                <table
                  class="table table-bordered"
                  style={{ backgroundColor: "#fdfdfd" }}
                >
                  <thead>
                    <tr>
                      <th scope="col">NAME</th>
                      <th scope="col">VALUE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.responsePackage && data.responsePackage.headerCouples &&
                      data.responsePackage.headerCouples.map((header) => (
                        <tr>
                          <td>{header.key}</td>
                          <td>{header.value}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              }
              <h6>Body</h6>
              {loading ? <List /> :
                <Viewer data={data.responsePackage} />
              }
            </div>
          </Tab>
        </Tabs>
      </div>
    )
  );
}
