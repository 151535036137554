/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { forwardRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import { Tooltip } from "@material-ui/core";
import { useSubheader } from "../../../_core/MetronicSubheader";
import { getNodes, refreshNode } from "../../../../../app/utils/api";
import { NavLink } from "react-router-dom";

import * as auth from "../../../../../app/modules/Auth/_redux/authRedux";

const QuickActionsDropdownToggle = forwardRef((props, ref) => {
  return (
    <a
      href="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
      id="kt_subheader_quick_actions"
      className={`btn btn-secondary btn-fixed-height font-weight-bold px-2 px-lg-5 mr-2`}
    >
      <span className="svg-icon svg-icon-lg">
        <SVG
          src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-down.svg")}
        />
      </span>
      {` `}Node List
    </a>
  );
});

function QuickActions(props) {
  const [nodes, handleNodes] = useState([]);
  const subheader = useSubheader();

  const nodeClick = (id) => {
    refreshNode({ nodeId: id, value: props.userRefreshToken }).then(
      (response) => {
        props.refreshNode(
          response.data.accessToken,
          response.data.refreshToken,
          response.data.nodeId
        );
      }
    );
  };

  useEffect(() => {
    getNodes().then((response) => {
      handleNodes(response.data);
    });
  }, []);

  return (
    <>
      {subheader.title === "Stream Page" && (
        <Dropdown className="dropdown-inline" drop="down" alignRight>
          <Dropdown.Toggle
            as={QuickActionsDropdownToggle}
            id="dropdown-toggle-quick-actions-subheader"
          />

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
            <ul className="navi navi-hover">
              <li className="navi-header font-weight-bold py-5">
                <span className="font-size-lg">Choose Node:</span>
                <Tooltip title="Will be available soon">
                  <i
                    className="flaticon-questions-circular-button icon-md text-muted"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Click to learn more..."
                  ></i>
                </Tooltip>
              </li>
              <li className="navi-separator mb-3 opacity-70"></li>

              {nodes.map((node, index) => (
                <li key={index} className="navi-item">
                  <a
                    href="#"
                    onClick={() => nodeClick(node.id)}
                    className="navi-link"
                  >
                    <span className="navi-text">
                      <span
                        className={`label label-xl label-inline  ${
                          node.isCurrentNode
                            ? "label-light-warning"
                            : "label-light-default"
                        }`}
                      >
                        {node.userFriendlyName}
                      </span>
                    </span>
                  </a>
                </li>
              ))}

              <li className="navi-separator mt-3 opacity-70"></li>
              <li className="navi-footer pt-5 pb-4">
                <NavLink
                  className="btn btn-clean font-weight-bold btn-sm"
                  to="/node-users"
                >
                  <i className="ki ki-gear icon-sm"></i>
                  Manage Node
                </NavLink>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}

export default connect(({ auth }) => {
  return { user: auth.user, userRefreshToken: auth.refreshToken };
}, auth.actions)(QuickActions);
