import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { resetPassword } from "../../../utils/api";



const initialValues = {
  newPassword: "",
  password_confirmation: "",
};

function ResetPassword() {

  const location = useLocation()
  const [isRequested, setIsRequested] = useState(false)


  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const Schema = Yup.object().shape({
    newPassword: Yup.string().required("New Password is required").matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
    password_confirmation: Yup.string()
      .required("Password confirmation is required")
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });



  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {

      let query = new URLSearchParams(location.search)
      let email = query.get("email-address")
      let token = query.get("Code")

      resetPassword({ token, email, ...values }).then(response => {
        toast.success('User password reset successfully.');
        setStatus("User password reset successfully.");
        setIsRequested(true)
      }).catch(error => {
        setSubmitting(false)
        toast.error(error.response.data.UserFriendlyMessage);

      })

    },
  });



  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="card border-0">
        <div className="card-body d-flex justify-content-center">
          <div className="login-form login-signin" style={{ display: "block" }}>
            <div className="text-center mb-10 mb-lg-20">
              <h3 className="font-size-h1">
                Reset Password
              </h3>
              <p className="text-muted fw-bold">
                Reset your account password
              </p>
            </div>

            <form
              id="kt_login_signin_form"
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
              onSubmit={formik.handleSubmit}
            >

              {formik.status && (
                <div className="mb-10 alert alert-primary alert-dismissible">
                  <div className="alert-text fw-bold">
                    {formik.status}
                  </div>
                </div>
              )}


              {/* begin: Password */}
              <div className="form-group mb-3">
                <input
                  placeholder="Password"
                  type="password"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    formik, "newPassword"
                  )}`}
                  name="newPassword"
                  {...formik.getFieldProps("newPassword")}
                />
                {formik.touched.newPassword && formik.errors.newPassword ? (
                  <small className="text-muted form-text">
                    {formik.errors.newPassword}
                  </small>
                ) : null}
              </div>
              {/* end: Password */}

              {/* begin: Confirm Password */}
              <div className="form-group mb-3">
                <input
                  placeholder="Confirm Password"
                  type="password"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    formik, "password_confirmation"
                  )}`}
                  name="password_confirmation"
                  {...formik.getFieldProps("password_confirmation")}
                />
                {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
                  <small className="text-muted form-text">
                    {formik.errors.password_confirmation}
                  </small>
                ) : null}
              </div>
              {/* end: Confirm Password */}

              {isRequested ? <div className="form-group d-flex flex-wrap flex-center">
                <Link to="/logout">
                  <button
                    type="button"
                    className="btn btn-light-primary fw-bold px-9 py-4 my-3 mx-4"
                  >
                    Return to sign in
                  </button>
                </Link>
              </div> : <div className="form-group d-flex flex-wrap flex-center">
                <button
                  type="submit"
                  disabled={
                    formik.isSubmitting || (formik.touched && !formik.isValid)
                  }
                  className="btn btn-primary fw-bold px-9 py-4 my-3 mx-4"
                >
                  <span>Submit</span>
                  {formik.isSubmitting && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}
                </button>
                <Link to={location.pathname == "/account/forgot-password" ? "/user-profile/change-password" : "/logout"}>
                  <button
                    type="button"
                    className="btn btn-light-primary fw-bold px-9 py-4 my-3 mx-4"
                  >
                    Cancel
                  </button>
                </Link>
              </div>
              }

            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword