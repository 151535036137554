import React from "react";
import ReactJson from "react-json-view";
import XMLViewer from "react-xml-viewer";

export default function Viewer(props) {
  const { data } = props;
  let parsed;

  if (data && data.body) {
    try {
      parsed = JSON.parse(data.body);
    } catch (e) {
      console.log(e)
    }
  }


  return (
    <div>
      {data && data.body ? (
        <div>
          {parsed ? (
            <ReactJson displayDataTypes={false} src={parsed}></ReactJson>
          ) : (
            data.body.indexOf("<?xml") != "-1" ?
              <XMLViewer xml={data.body} />
              :
              <div className="border border-dark">{data.body}</div>
          )}
        </div>
      ) : (
        <div className="text-center">There is no data</div>
      )}
    </div>
  );
}
