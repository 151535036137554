import * as auth from "../app/modules/Auth/_redux/authRedux";
import { refreshNode } from "../app/utils/api";
import https from "https"


const { REACT_APP_CLIENT_ID } = process.env;
const UNAUTHORIZED = 401;

export default function setupAxios(axios, store) {

  const agent = new https.Agent({
    rejectUnauthorized: false
  });


  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      config.headers["Alg-Client-Id"] = REACT_APP_CLIENT_ID;
      config.httpsAgent = agent

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const {
        auth: { refreshToken, nodeId },
      } = store.getState();

      if (error.config && error.response && error.response.status === UNAUTHORIZED) {
        if (refreshToken) {

          return refreshNode({ nodeId: nodeId, value: refreshToken }).then(
            (response) => {
              error.config._retry = true;
              error.config.headers.Authorization = `Bearer ${response.data.accessToken}`;

              store.dispatch(
                auth.actions.refreshToken(
                  response.data.accessToken,
                  response.data.refreshToken,
                  response.data.nodeId
                )
              );
              store.dispatch(auth.actions.setConnection(true))

              return axios(error.config);
            }
          ).catch(() => store.dispatch(auth.actions.logout()))

        } else {
          store.dispatch(auth.actions.logout());
        }
      }

      return Promise.reject(error);
    }
  );
}
