/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useFormik } from "formik";
import { shallowEqual, useSelector } from "react-redux";
import { requestPassword } from "../../utils/api";
import { toast, ToastContainer } from 'react-toastify';


function ChangePassword(props) {
  const user = useSelector(({ auth }) => auth.user, shallowEqual);


  const formik = useFormik({
    initialValues:{},
    onSubmit: (values, { setStatus, setSubmitting }) => {

      setSubmitting(true)

      requestPassword({ email: user.email })
        .then(() => {
          setStatus("Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.");
          setSubmitting(false)
        })
        .catch((error) => {
          setSubmitting(false);
          toast.error(error.response.data.UserFriendlyMessage);
        });
    },
  });


  return (

    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form className="card card-custom" onSubmit={formik.handleSubmit}>
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">
              Change Password
            </h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              Change your account password
            </span>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Form */}
        <div className="card-body">
          {formik.status && (
            <div className="mb-10 alert alert-success alert-dismissible">
              <div className="alert-text font-weight-bold">
                {formik.status}
              </div>
            </div>
          )}

          <div className="form-group d-flex flex-wrap flex-start">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3"
              disabled={formik.isSubmitting}
            >
              Send a Change Password Email
              {formik.isSubmitting && <span className="spinner-border spinner-border-sm align-middle ml-2"></span>}

            </button>

          </div>


        </div>
      </form>
    </>
  );
}

export default ChangePassword
