import React from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../../../utils/api";
import { toast, ToastContainer } from 'react-toastify';


const initialValues = {
  data: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const ForgotPasswordSchema = Yup.object().shape({
    data: Yup.string()
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {


      let type = values.data.indexOf("@") == "-1" ? "username" : "email"
      setSubmitting(true)

      requestPassword({ [type]: values.data })
        .then(() => {
          setStatus("Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.");
          setSubmitting(false)
        })
        .catch((error) => {
          setSubmitting(false);
          toast.error(error.response.data.UserFriendlyMessage);
        });
    },
  });

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="login-form login-forgot" style={{ display: "block" }}>

        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">Forgotten Password ?</h3>
          <div className="text-muted font-weight-bold">
            Enter your username or email  to reset your password
          </div>
        </div>




        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >



          {formik.status && (
            <div className="mb-10 alert alert-primary alert-dismissible">
              <div className="alert-text font-weight-bold">
                {formik.status}
              </div>
            </div>
          )}



          <div className="form-group fv-plugins-icon-container">
            <input
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "data"
              )}`}
              name="data"
              placeholder="Email address or username"
              {...formik.getFieldProps("data")}
            />
            {formik.touched.data && formik.errors.data ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.data}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={formik.isSubmitting}
            >
              Submit
              {formik.isSubmitting && <span className="spinner-border spinner-border-sm align-middle ml-2"></span>}

            </button>
            <Link to="/auth">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
