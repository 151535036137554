import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const REQUEST_PASSWORD_URL = `${REACT_APP_API_URL}/api/Account/ForgotPassword`;
export const RESET_PASSWORD_URL = `${REACT_APP_API_URL}/api/Account/ChangePassword`;
export const LOGIN_URL = `${REACT_APP_API_URL}/api/Account/Auth/Token`;
export const REGISTER_URL = `${REACT_APP_API_URL}/api/Account/Register`;
export const SEND_VERIFICATION_EMAIL = `${REACT_APP_API_URL}/api/Account/SendEmailVerificationRequest`;
export const VERIFY_EMAIL = `${REACT_APP_API_URL}/api/Account/VerifyEmail`;
export const NODES_URL = `${REACT_APP_API_URL}/api/SharedNode/Nodes`;
export const DEVICES_URL = `${REACT_APP_API_URL}/api/Device/Devices`;
export const APPS_URL = `${REACT_APP_API_URL}/api/App/Apps`;
export const APP_URL = `${REACT_APP_API_URL}/api/App/Information`;
export const REGISTER_APP_URL = `${REACT_APP_API_URL}/api/App/Register`;
export const UPDATE_APP_URL = `${REACT_APP_API_URL}/api/App/Update`;
export const CONNECTION_URL = `${REACT_APP_API_URL}/api/Connection/negotiate`;
export const PROFILE_URL = `${REACT_APP_API_URL}​/api/Profile/Information`;
export const DEFAULT_NODE_URL = `${REACT_APP_API_URL}​/api/SharedNode/Default`;
export const REFRESH_URL = `${REACT_APP_API_URL}​/api/Account/Auth/Refresh`;
export const NODE_USERS_URL = `${REACT_APP_API_URL}/api/SharedNode/NodeUsers`;
export const INVITE_USER_URL = `${REACT_APP_API_URL}/api/SharedNode/InviteUser`;
export const DELETE_USER_URL = `${REACT_APP_API_URL}/api/SharedNode/DeleteUser`;
export const APPS_BY_DEVICE_URL = `${REACT_APP_API_URL}​/api/StreamFilter/AppsByDevice`;
export const DEVICES_BY_APP_URL = `${REACT_APP_API_URL}​/api/StreamFilter/DevicesByApp`;
export const PACKAGE_URL = `${REACT_APP_API_URL}/api/Data/GetPackage`;

export const ME_URL = "api/me";

export function login(username, password) {
  return axios.post(LOGIN_URL, { username, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function sendVerificationEmail(email) {
  return axios.post(SEND_VERIFICATION_EMAIL, { email });
}

export function verifyEmail(data) {
  return axios.post(VERIFY_EMAIL, data);
}

export function requestPassword(data) {
  return axios.post(REQUEST_PASSWORD_URL, data);
}

export function resetPassword(data) {
  return axios.post(RESET_PASSWORD_URL, data);
}

export function getNodes() {
  return axios.get(NODES_URL);
}

export function getDevices() {
  return axios.get(DEVICES_URL);
}

export function getAppsByDevice(id) {
  return axios.get(`${APPS_BY_DEVICE_URL}?deviceId=${id}`);
}

export function getDevicesByApp(id) {
  return axios.get(`${DEVICES_BY_APP_URL}?appId=${id}`);
}

export function getDefaultNode() {
  return axios.get(DEFAULT_NODE_URL);
}

export function getApps() {
  return axios.get(APPS_URL);
}

export function getApp(id) {
  return axios.get(`${APP_URL}?id=${id}`);
}

export function getConnectionInfo() {
  return axios.post(CONNECTION_URL, null);
}

export function registerApp(data) {
  return axios.post(REGISTER_APP_URL, data);
}

export function updateApp(data) {
  return axios.put(UPDATE_APP_URL, data);
}

export function getUserByToken() {
  return axios.get(PROFILE_URL);
}

export function refreshNode(data) {
  return axios.post(REFRESH_URL, data);
}

export function getPackage(id) {
  return axios.get(`${PACKAGE_URL}?id=${id}`);
}

export function getNodeUsers() {
  return axios.get(NODE_USERS_URL);
}

export function inviteNodeUser(data) {
  return axios.post(INVITE_USER_URL, data);
}

export function deleteNodeUser(data) {
  return axios.post(DELETE_USER_URL, data);
}

export function getIosMd() {
  return fetch(
    `https://raw.githubusercontent.com/betalgo/LCE-Documentation/main/iOS/Setup.md`
  ).then((response) => response.text());
}

export function getAndroidMd() {
  return fetch(
    `https://raw.githubusercontent.com/betalgo/LCE-Documentation/main/Andorid/Setup.md`
  ).then((response) => response.text());
}

export function getCoreEndpointListenerMD() {
  return fetch(
    `https://raw.githubusercontent.com/betalgo/LCE-Documentation/main/DotNet/Setup-EndpointListener.md`
  ).then((response) => response.text());
}

export function getCoreHttpClientListenerMD() {
  return fetch(
    `https://raw.githubusercontent.com/betalgo/LCE-Documentation/main/DotNet/Setup-HttpClientListener.md`
  ).then((response) => response.text());
}
export function getHttpClientListenerStandardMD() {
  return fetch(
    `https://raw.githubusercontent.com/betalgo/LCE-Documentation/main/DotNet/Setup-HttpClientListener-DotNetStandard.md`
  ).then((response) => response.text());
}
