import React from "react";
import moment from "moment";
import methodTypes from "../utils/methodTypes";
import BootstrapTable from "react-bootstrap-table-next";

const columns = [{
  text: 'TIME',
  formatter: (e, row) => {
    if (row.requestPackage) {
      return moment(row.requestPackage.timeStamp).format(
        "HH:mm:ss.SS"
      )
    }

  }
}, {
  text: 'STATUS',
  formatter: (e, row) => {
    if (row.responsePackage) {
      return row.responsePackage.statusCode
    }

  }
}, {
  text: 'METHOD',
  formatter: (e, row) => {
    if (row.requestPackage) {
      return methodTypes[row.requestPackage.methodType]
    }

  }
}, {
  text: 'PATH',
  formatter: (e, row) => {
    if (row.requestPackage) {
      return `${new URL(row.requestPackage.url).pathname} ${new URL(row.requestPackage.url).search}`
    }

  }
}, {
  text: 'DOMAIN',
  formatter: (e, row) => {
    if (row.requestPackage) {
      return new URL(row.requestPackage.url).origin
    }

  }
}];


export default function RequestTable(props) {
  return (
    <div
      className="table-responsive"
      style={{ flex: 2, height: "calc(100vh - 180px)" }}
    >
      <BootstrapTable
        keyField='id'
        data={props.data}
        columns={columns}
        rowClasses={(row) => props.activeItem === row.id ? "active" : ""}
        rowEvents={{
          onClick: (e, row, rowIndex) => props.selectItem(row)
        }}
      />
    </div>
  );
}
