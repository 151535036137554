import React, { useEffect } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { verifyEmail } from "../../../utils/api";



function VerifyEmail() {

  const location = useLocation()

  useEffect(() => {

    let query = new URLSearchParams(location.search)
    let email = query.get("email-address")
    let token = query.get("Code")


    verifyEmail({ token, email })
  }, [])

  return <Redirect to="/auth/login" />

}



export default VerifyEmail